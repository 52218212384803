@font-face {
  font-family: 'Roobert';
  src: local('Roobert-Light'),
  url('../assets/fonts/Roobert-Light.ttf') format('opentype');
  font-weight: 100 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert-Regular'),
  url('../assets/fonts/Roobert-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert-Medium'),
  url('../assets/fonts/Roobert-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert-SemiBold'),
  url('../assets/fonts/Roobert-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert-Bold'),
  url('../assets/fonts/Roobert-Bold.ttf') format('opentype');
  font-weight: 700 900;
}
