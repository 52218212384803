@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts";

body {
  font-family: 'Roobert', serif;

  @apply
  h-screen w-screen text-black-brand-1;
  #root {
    @apply h-full w-full;
  }
}

/* custom scrollbar */
*::-webkit-scrollbar {
  width: 0.34rem;
  height: 0.563rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #BDBDBD;
  border-radius: 0.34rem;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #828282;
}
